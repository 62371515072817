##{$footer} {
  div.line {
    border-bottom: 1px solid $white;
    height: 1px;
  }

  .map-frame {
    height: $lb-footer-map-frame-height;
    transition: $transition-all;
  }

  .info {
    // Section for the contact and opening hours
    section:first-of-type {
      section {
        min-width: $lb-footer-info-contact-and-opening-hours-min-width;
      }
    }

    section {
      // Logo sizing
      svg {
        max-width: $lb-footer-info-logo-max-width;
      }

      // Days of the week sizing
      span.weekdays {
        width: $lb-footer-info-weekdays-width;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .map-frame {
      height: $lb-footer-map-frame-height-lg;
    }

    .info {
      // Section for the contact and opening hours
      section:first-of-type {
        section {
          min-width: $lb-footer-info-contact-and-opening-hours-min-width-lg;
        }
      }

      section {
        // Logo sizing
        svg {
          max-width: $lb-footer-info-logo-max-width-lg;
        }

        // Days of the week sizing
        span.weekdays {
          width: $lb-footer-info-weekdays-width-lg;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    section {
      // Logo sizing
      svg {
        max-width: $lb-footer-info-logo-max-width-xl;
      }
    }
  }
}

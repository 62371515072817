body {
  background: $white;
  font-family: $font-family-sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-serif;
}

/* Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: $primary;
}

::-webkit-scrollbar-thumb {
  background-color: $white;
  border: 4px solid $white;
}

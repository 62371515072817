nav#navbar {
  transition: $transition-all, background-color 0s;
  height: $navbar-height;
  z-index: $zindex-offcanvas;

  // When navbar is fixed on top of the screen
  &.navbar--top {
    height: $navbar-top-height;
    transition: background-color 0s;

    .container-fluid {
      z-index: $zindex-offcanvas-logo;

      .navbar-brand {
        animation: slide-to-down-with-rotate 0.3s;
        left: map-get($spacers, 3);
        height: $navbar-top-brand-size;
        width: $navbar-top-brand-size;
        transform: rotate(45deg);
        margin: 0;

        svg {
          transform: rotate(-45deg);
        }
      }

      .navbar-toggler:has(+ div.offcanvas:not(.show)) > svg {
        @extend .fill-primary;
      }

      .offcanvas {
        &-background-lines {
          top: -20px;
        }

        &::before {
          @extend .bg-primary;
          @extend .position-absolute;
          @extend .top-0;

          content: '';
          width: $breakpoint-lg;
          height: $navbar-top-height;
          transform: translateX(-100%);
        }

        &-end {
          @extend .top-0;
          padding-top: $navbar-top-height;
        }

        &-backdrop {
          top: $navbar-top-height;
        }
      }
    }
  }

  // Navbar top ended
  .container-fluid {
    max-width: $breakpoint-xxl;
    z-index: inherit;

    .navbar-brand,
    .navbar-toggler {
      z-index: inherit;

      svg {
        z-index: inherit;
      }
    }

    .navbar-brand {
      height: $navbar-brand-size;
      margin-left: $lb-navbar-brand-left-margin;
    }

    .navbar-toggler {
      @extend .border-0;
      z-index: inherit;

      // The navbar toggler has a hamburger icon that uses the following
      // styles to change its content to an "X" when the offcanvas is shown
      // or hidden
      .hamburger-and-close-icon {
        transition: $transition-all;
        height: $navbar-toggler-icon-size;
        width: $navbar-toggler-icon-size;
      }

      // Animation when offcanvas is open
      &:has(+ div.offcanvas.show) {
        #top {
          transition: $transition-all;
          transform-origin: 3px 15px;
          transform: rotate(45deg);
        }

        #mid {
          transition: $transition-all;
          display: none;
        }

        #bottom {
          transition: $transition-all;
          transform-origin: 3px 30px;
          transform: rotate(-45deg);
        }
      }

      // Animation when offcanvas is closed
      &:has(+ div.offcanvas:not(.show)) {
        #top {
          transition: $transition-all;
          transform-origin: 3px 15px;
          transform: none;
        }

        #mid {
          transition: $transition-all;
          display: block;
        }

        #bottom {
          transition: $transition-all;
          transform-origin: 3px 30px;
          transform: none;
        }
      }

      &:focus {
        @extend .shadow-none;
      }
    }

    ul.navbar-nav {
      li.nav-item {
        .nav-link {
          cursor: pointer;

          &.active {
            text-shadow: 0px 0px 12px $black;

            .active-item-background-lines {
              display: block;
            }
          }
        }

        .active-item-background-lines {
          left: 0;
          top: 0;
          stroke-width: $lb-navbar-active-item-background-lines-stroke-width;
          width: 100%;

          path {
            stroke-dasharray: $lb-navbar-active-item-background-lines-stroke-array-and-offset;
            stroke-dashoffset: $lb-navbar-active-item-background-lines-stroke-array-and-offset;
          }

          // Add an animation when the nav-link has an .active class
          &:has(~ .nav-link.active) {
            path {
              animation: draw-svg 0.3s forwards;
            }
          }
        }
      }
    }

    .offcanvas {
      &-background-lines {
        transition: top $transition-all, left $transition-all;
        top: $lb-navbar-offcanvas-top-position;
        left: $lb-navbar-offcanvas-left-position;
        stroke-width: $lb-navbar-offcanvas-background-lines-stroke-width;
        stroke-linecap: round;

        path {
          stroke-dasharray: $lb-navbar-offcanvas-background-lines-stroke-array-and-offset;
          stroke-dashoffset: $lb-navbar-offcanvas-background-lines-stroke-array-and-offset;
          animation: draw-svg 10s infinite alternate-reverse;
        }
      }

      &-end,
      &-backdrop {
        top: $navbar-height;
      }
    }
  }

  // Media queries
  @include media-breakpoint-up(sm) {
    .offcanvas {
      &-background-lines {
        left: $lb-navbar-offcanvas-left-position-sm;
      }
    }
  }
}

@import '~bootstrap/scss/functions';

// scss-docs-start gray-colors-map
$grays: (
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
) !default;
// scss-docs-end gray-colors-map

// scss-docs-start white-colors-map
$whites: (
  'white-100': $white-100,
  'white-200': $white-200,
  'white-300': $white-300,
  'white-400': $white-400,
  'white-500': $white-500,
) !default;
// scss-docs-end white-colors-map

// scss-docs-start black-colors-map
$blacks: (
  'black-100': $black-100,
  'black-200': $black-200,
  'black-300': $black-300,
  'black-400': $black-400,
  'black-500': $black-500,
) !default;
// scss-docs-end black-colors-map

// scss-docs-start primary-colors-map
$primaries: (
  'primary-100': $primary-100,
  'primary-200': $primary-200,
  'primary-300': $primary-300,
  'primary-400': $primary-400,
  'primary-500': $primary-500,
) !default;
// scss-docs-end primary-colors-map

// scss-docs-start secondary-colors-map
$secondaries: (
  'secondary-100': $secondary-100,
  'secondary-200': $secondary-200,
  'secondary-300': $secondary-300,
  'secondary-400': $secondary-400,
  'secondary-500': $secondary-500,
) !default;
// scss-docs-end secondary-colors-map

// scss-docs-start colors-map
$colors: (
  'red': $red,
  'yellow': $yellow,
  'green': $green,
  'cyan': $cyan,
  'black': $black,
  'white': $white,
  'gray': $gray,
) !default;
// scss-docs-end colors-map

// scss-docs-start theme-colors-map
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
) !default;
// scss-docs-end theme-colors-map

// scss-docs-start all-colors-map
$colors-map: map-merge($colors, $theme-colors);
// scss-docs-end all-colors-map

// scss-docs-start all-colors-and-shades-map
$colors-and-shades-map: map-merge-multiple(
  $grays,
  $whites,
  $blacks,
  $primaries,
  $secondaries,
  (
    transparent: transparent,
  )
);
// scss-docs-end all-colors-and-shades-map

// scss-docs-start font-sizes
$font-sizes: (
  h1: $h1-font-size,
  h2: $h2-font-size,
  h3: $h3-font-size,
  h4: $h4-font-size,
  h5: $h5-font-size,
  body1: $body1-font-size,
  body2: $body2-font-size,
  body3: $body3-font-size,
  body4: $body4-font-size,
) !default;
// scss-docs-end font-sizes

// scss-docs-start font-families-map
$font-families-map: (
  'sans-serif': $font-family-sans-serif,
  'sans-serif-fallback': $font-family-sans-serif-fallback,
  'serif': $font-family-serif,
);
// scss-docs-end font-families-map

// scss-docs-start spacer-variables-maps
$spacers: (
  0: 0,
  1: $spacer-1,
  2: $spacer-2,
  3: $spacer-3,
  4: $spacer-4,
  5: $spacer-5,
  6: $spacer-6,
  7: $spacer-7,
  8: $spacer-8,
  9: $spacer-9,
) !default;
// scss-docs-end spacer-variables-maps

// scss-docs-start position-map
$position-values: (
  0: 0,
  50: 50%,
  100: 100%,
  auto: auto,
) !default;
// scss-docs-end position-map

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: $breakpoint-xs,
  sm: $breakpoint-sm,
  md: $breakpoint-md,
  lg: $breakpoint-lg,
  xl: $breakpoint-xl,
  xxl: $breakpoint-xxl,
) !default;
// scss-docs-end grid-breakpoints

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: $container-sm,
  md: $container-md,
  lg: $container-lg,
  xl: $container-xl,
  xxl: $container-xxl,
) !default;
// scss-docs-end container-max-widths

// scss-docs-start percentage-values-map
$sizing-percentage-values-map: (
  0: 0%,
  25: 25%,
  33: 33%,
  50: 50%,
  66: 66%,
  75: 75%,
  100: 100%,
);
// scss-docs-end percentage-values-map

// scss-docs-start max-min-values-map
$sizing-values-map: (
  min-content: min-content,
  max-content: max-content,
  fit-content: fit-content,
);
// scss-docs-end max-min-values-map

// scss-docs-start opacity-values-map
$opacity-values-map: ();

// Loop through values from 0 to 100
@for $i from 0 through 100 {
  // Divide the current value by 100 to get the opacity value
  $opacity-values-map: map-merge(
    $opacity-values-map,
    (
      $i: $i / 100,
    )
  );
}

// scss-docs-end opacity-values-map

// scss-docs-start transform-rotate-values-map
$transform-rotate-values-map: ();

// Loop through values from 0 to 360 with increments of 15
@for $i from 0 through 360 {
  // Add the current value to the transform-rotate-values-map
  $transform-rotate-values-map: map-merge(
    $transform-rotate-values-map,
    (
      $i: rotate(#{$i}deg),
    )
  );
  // Increment the current value by 15 to get the next rotation value
  $i: $i + 15; // Increase the value by 15 to create a pattern of 15-degree increments
}

// scss-docs-end transform-rotate-values-map
// Loop through values from 0 to 360 with increments of 15
@for $i from 0 through 360 {
  // Add the current value to the transform-rotate-values-map
  $transform-rotate-values-map: map-merge(
    $transform-rotate-values-map,
    (
      $i: rotate(#{$i}deg),
    )
  );
  // Increment the current value by 15 to get the next rotation value
  $i: $i + 15; // Increase the value by 15 to create a pattern of 15-degree increments
}

// scss-docs-start box-shadow-values-map
$box-shadow-values-map: (
  null: $box-shadow,
  'sm': $box-shadow-sm,
  'lg': $box-shadow-lg,
  'inset': $box-shadow-inset,
  none: none,
);
// scss-docs-end box-shadow-values-map

// scss-docs-start cursor-values-map
$cursor-values-map: (
  pointer: pointer,
);
// scss-docs-end cursor-values-map

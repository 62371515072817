@font-face {
  font-family: 'Acumin Pro';
  src: url('./fonts/Acumin-RPro.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Hatton';
  src: url('./fonts/Hatton-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

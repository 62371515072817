.social-buttons {
  transition: 0.3s;
  z-index: $zindex-social-buttons;

  left: $lb-social-buttons-left-position;

  &:hover {
    left: 0;
  }

  section,
  button {
    transition: 0.3s;
  }

  &--inner-buttons {
    transition: 0.3s;
    opacity: 0;

    // Position the inner-icons container 30px above
    transform: $lb-social-inner-buttons-transform-translate;

    // Loop that iterates from 1 to 10
    @for $i from 1 through 10 {
      // Select each button in the container starting from the last one
      button:nth-last-child(#{$i}) {
        // Apply a y translation to keep the buttons behind the main plus button
        // The logic below creates values like 235%, 370%, and so on
        transform: translateY(calc(100% + #{$i * 100%} + #{$i * 35%})) rotate(45deg);
        // This creates a slide animation when activating/deactivating the inner icons container
      }

      &-active {
        opacity: 1;

        // This is written this way to address specificity issues
        // If not written like this, 'button:nth-last-child(#{$i})' above would
        // have higher specificity than the active one, causing incorrect styling
        button:nth-last-child(#{$i}) {
          // When active, remove the y translation and keep the rotation transform
          transform: rotate(45deg);
        }
      }
    }

    button {
      width: $lb-social-inner-buttons-size;
      height: $lb-social-inner-buttons-size;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  @include media-breakpoint-up(md) {
    left: $lb-social-buttons-left-position-md;
  }
}

// When navbar is fixed on top of the screen
.social-buttons--top {
  .social-buttons--inner-buttons {
    bottom: map-get($spacers, 2);
  }
}

##{$initial} {
  min-height: $lb-initial-minimum-height;

  .section-background {
    min-height: $lb-initial-minimum-height;

    source,
    img {
      object-fit: cover;
      object-position: 75% 100%;
    }
  }

  .carousel {
    transition: 0.3s;
    max-width: $container-xl;
    text-shadow: $text-shadow;

    .carousel-item {
      transition: $transition-all;
      animation: fade-in 1s forwards;
    }
  }

  @include media-breakpoint-up(md) {
    min-height: $lb-initial-minimum-height-md;

    .section-background {
      min-height: $lb-initial-minimum-height-md;
    }
  }

  @media screen and (max-height: 600px) and (max-width: 576px) {
    .carousel {
      padding: $lb-initial-carousel-padding;
    }
  }

  @media screen and (max-height: 700px) and (min-width: 576px) {
    .carousel {
      padding: $lb-initial-carousel-padding;
    }
  }
}

##{$team} {
  height: fit-content;

  .section-background {
    source,
    img {
      object-fit: none;
    }
  }

  section.teams-container {
    scroll-snap-type: x mandatory;
    height: $lb-team-teams-container-min-height;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .slider-arrow {
    max-height: $lb-team-slider-arrow-min-height;
  }

  @include media-breakpoint-up(md) {
    min-height: 100vh;

    .slider-arrow {
      max-height: 100%;
    }
  }
}

// Color system

// scss-docs-start gray-color-variables
// Gray
$gray-100: #beb496;
$gray-200: #a08c6e;
$gray-300: #697c61;
$gray-400: #4b5a46;
$gray-500: #283728;

$gray: $gray-300;

// White
$white-100: #f5ebdc;
$white-200: #ffbb04;
$white-300: #f5ebdc;
$white-400: #cf4a38;
$white-500: #d9d9d9;

$white: $white-300;

// Black
$black-100: #1d1f1d;
$black-200: #202321;
$black-300: #222f22;
$black-400: #2d2f2d;
$black-500: #323533;

$black: $black-300;
// scss-docs-end gray-color-variables

// Primary
$primary-100: #1d2c1d;
$primary-200: #222f22;
$primary-300: #283728;
$primary-400: #2d3c2d;
$primary-500: #334633;

// Secondary
$secondary-100: #8f7a61;
$secondary-200: #947d64;
$secondary-300: #a08c6e;
$secondary-400: #aa9d85;
$secondary-500: #b9ac99;

// scss-docs-start color-variables
$red: #cf4a38 !default;
$yellow: #ffbb04 !default;
$green: #5b9632 !default;
$cyan: #1a9994 !default;
// scss-docs-end color-variables

// scss-docs-start theme-color-variables
$primary: $primary-300 !default;
$secondary: $secondary-300 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
// scss-docs-end theme-color-variables

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Acumin Pro', system-ui, -apple-system, 'Segoe UI', Roboto,
  'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-family-sans-serif-fallback: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue',
  'Noto Sans', 'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-family-serif: 'Hatton' !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;

$line-height-base: 1.25 !default;
$line-height-sm: 1 !default;
$line-height-lg: 1.75 !default;

$h1-font-size: $font-size-base * 3.75 !default;
$h2-font-size: $font-size-base * 3 !default;
$h3-font-size: $font-size-base * 2.25 !default;
$h4-font-size: $font-size-base * 1.875 !default;
$h5-font-size: $font-size-base * 1.5 !default;
$body1-font-size: $font-size-base * 1.25 !default;
$body2-font-size: $font-size-base * 1.125 !default;
$body3-font-size: $font-size-base !default;
$body4-font-size: $font-size-base * 0.75 !default;
// scss-docs-end font-variables

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacer-1: $spacer * 0.25 !default;
$spacer-2: $spacer * 0.5 !default;
$spacer-3: $spacer !default;
$spacer-4: $spacer * 1.5 !default;
$spacer-5: $spacer * 2 !default;
$spacer-6: $spacer * 2.5 !default;
$spacer-7: $spacer * 3 !default;
$spacer-8: $spacer * 3.5 !default;
$spacer-9: $spacer * 5 !default;

// Text shadow

// scss-docs-start text-shadows

$text-shadow: 4px 2px 8px rgba(0, 0, 0, 0.75);

// scss-docs-end text-shadows

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1140px;
$container-xxl: 1320px;

// Links
//
// Style anchor elements.

$link-hover-color: $secondary-500 !default;

// Z-index master list

// scss-docs-start zindex-stack
$zindex-about-us-text-content: 490 !default;
$zindex-title-container-title-content: 500 !default;
$zindex-work-field-initials: 510 !default;
$zindex-work-field-initials-paragraph: 520 !default;
$zindex-social-buttons: 700 !default;
$zindex-offcanvas-logo-backdrop: 1046 !default;
$zindex-offcanvas-logo: 1047 !default;
$zindex-no-match-content: 1000 !default;
$zindex-cookie-consent: 1100 !default;
$zindex-cookie-consent-text: 1101 !default;

// scss-docs-end zindex-stack

// scss-docs-start transition
$transition-default-time: 0.3s !default;
$transition-all: $transition-default-time !default;
// scss-docs-end transition

// scss-docs-start element-sizes
$navbar-height: 60px !default;
$navbar-brand-size: 44px !default;
$navbar-toggler-icon-size: 36px !default;
$navbar-top-height: 156px !default;
$navbar-top-brand-size: 140px !default;
// scss-docs-end element-sizes

// scss-docs-start box-shadow-variables
$box-shadow: 0 0.5rem 1rem rgba($black-100, 0.25) !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black-100, 0.5) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black-100, 0.75) !default;
$box-shadow-inset: inset 0 1px 2px rgba($black-100, 0.5) !default;
// scss-docs-end box-shadow-variables

// Lombardi & Boeing variables

// scss-docs-start colors
$lb-employee-card-content-gradient-background: linear-gradient(
  180deg,
  rgba(29, 44, 29, 0) 0%,
  #1d2c1d 100%
);
// scss-docs-end colors

// scss-docs-start text
$lb-about-us-title-font-size: 80px;
$lb-about-us-title-font-size-sm: 120px;
// scss-docs-end text

// scss-docs-start sizing
$lb-social-inner-buttons-size: 45px;
$lb-title-container-content-container-width-md: 320px;
$lb-initial-minimum-height: max(100vh, 800px);
$lb-initial-minimum-height-md: max(100vh, 950px);
$lb-initial-carousel-padding: 0 10px 0 70px;
$lb-work-field-height: 110px;
$lb-work-field-height-md: 168px;
$lb-work-field-initials-size: 80px;
$lb-work-field-initials-size-md: 120px;
$lb-work-field-details-max-width: 800px;
$lb-work-field-details-before-width: 60px;
$lb-work-field-details-before-width-md: 90px;
$lb-work-field-minimum-height: 650px;
$lb-work-field-minimum-height-md: 1000px;
$lb-work-field-min-height: max(725px, $lb-work-field-minimum-height);
$lb-work-field-max-height: min(725px, $lb-work-field-minimum-height);
$lb-work-field-min-height-md: max(100vh, $lb-work-field-minimum-height-md);
$lb-team-minimum-height: 480px;
$lb-team-min-height: max(100vh, $lb-team-minimum-height);
$lb-team-teams-container-minimum-height: 320px;
$lb-team-teams-container-min-height: max(70vh, $lb-team-teams-container-minimum-height);
$lb-team-slider-arrow-min-height: 40px;
$lb-team-slider-arrow-width: 49%;
$lb-team-slider-arrow-width-md: auto;
$lb-team-slider-width-disabled: 4px;
$lb-team-slider-width-md: 100%;
$lb-team-slider-max-width-md: 40px;
$lb-team-slider-min-width-md: 4px;
$lb-contact-minimum-height: 800px;
$lb-contact-min-height: max(100vh, $lb-contact-minimum-height);
$lb-footer-map-frame-height: 200px;
$lb-footer-map-frame-height-lg: 340px;
$lb-footer-info-contact-and-opening-hours-min-width: 260px;
$lb-footer-info-contact-and-opening-hours-min-width-lg: 340px;
$lb-footer-info-logo-max-width: 320px;
$lb-footer-info-logo-max-width-lg: 600px;
$lb-footer-info-logo-max-width-xl: 400px;
$lb-footer-info-weekdays-width: 78px;
$lb-footer-info-weekdays-width-lg: 110px;
$lb-no-match-minimum-height: max(100vh, 300px);
$lb-no-match-content-max-width: 300px;
$lb-no-match-content-max-width-sm: 560px;
$lb-no-match-content-title-size: 128px;
$lb-no-match-content-title-size-sm: 256px;

// scss-docs-start position
$lb-employee-card-content-socials-right-position: -64px; // The size of the icons
$lb-navbar-offcanvas-top-position: -150px;
$lb-navbar-offcanvas-left-position: -575px;
$lb-navbar-offcanvas-left-position-sm: -145%;
$lb-social-buttons-left-position: -70px;
$lb-social-buttons-left-position-md: -90px;
$lb-about-us-background-lines-x-position: -95%;
$lb-about-us-background-lines-x-position-sm: -50%;
$lb-about-us-background-lines-x-position-md: -30%;

// scss-docs-end position

// scss-docs-start transform
$lb-social-inner-buttons-transform-translate: translate3d(0px, -30px, 0);
// scss-docs-end transform

// scss-docs-start margin
// This places the navbar brand in a position that is
// pixel perfect to centralize with the top navbar logo
$lb-navbar-brand-left-margin: 28px;
// scss-docs-end margin

// scss-docs-start svg
$lb-navbar-active-item-background-lines-stroke-width: 2px;
$lb-navbar-active-item-background-lines-stroke-array-and-offset: 100;
$lb-navbar-offcanvas-background-lines-stroke-width: 10px;
$lb-navbar-offcanvas-background-lines-stroke-array-and-offset: 12680;
$lb-about-us-background-lines-width: 15px;
$lb-button-loading-background-lines-stroke-width: 3px;
$lb-button-loading-background-lines-stroke-array-and-offset: 106;
$lb-team-slider-arrow-height: 30px;
$lb-team-slider-arrow-height-md: 45px;
// scss-docs-end svg

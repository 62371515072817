#no-match {
  min-height: $lb-no-match-minimum-height;

  .section-background {
    min-height: $lb-no-match-minimum-height;

    source,
    img {
      object-fit: cover;
    }
  }

  section.content {
    transition: 0.3s;
    max-width: $lb-no-match-content-max-width;

    z-index: $zindex-no-match-content;

    h1 {
      font-size: $lb-no-match-content-title-size;
    }

    a:hover {
      color: $primary-500;
    }
  }

  @include media-breakpoint-up(sm) {
    section.content {
      max-width: $lb-no-match-content-max-width-sm;

      h1 {
        font-size: $lb-no-match-content-title-size-sm;
      }
    }
  }
}

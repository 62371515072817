.work-field {
  height: $lb-work-field-height;
  transition: $transition-all;

  &--initials {
    height: $lb-work-field-initials-size;
    width: $lb-work-field-initials-size;
    z-index: $zindex-work-field-initials;

    &::before {
      content: '';
      transition: $transition-all;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background: $primary;
      transform: rotate(45deg);
    }

    p {
      transition: $transition-all;
      z-index: $zindex-work-field-initials-paragraph;
    }
  }

  &--details {
    height: 100%;
    max-width: $lb-work-field-details-max-width;
    transition: $transition-all;

    &::before {
      content: '';
      transition: $transition-all;

      height: 100%;
      width: $lb-work-field-details-before-width;
      position: absolute;
      top: 0;
      left: -#{$lb-work-field-details-before-width};

      background: inherit;
      clip-path: polygon(100% 0, 0% 0, 90% 50%, 0% 100%, 100% 100%);
    }
  }

  &:hover {
    .work-field {
      &--initials {
        @extend .opacity-100;

        p {
          transform: scale(1.4);
        }

        &::before {
          transform: rotate(405deg) scale(1.2);
          box-shadow: $box-shadow-lg;
        }
      }

      &--details {
        @extend .opacity-100;
        // Extending here cause it is not allowed to extend inside media queries
        @extend .ps-md-4;
      }
    }
  }

  @include media-breakpoint-up(md) {
    height: $lb-work-field-height-md;

    &--initials {
      height: $lb-work-field-initials-size-md;
      width: $lb-work-field-initials-size-md;
    }

    &--details {
      min-height: $lb-work-field-height-md;

      &::before {
        width: $lb-work-field-details-before-width-md;
        left: -#{$lb-work-field-details-before-width-md};
        clip-path: polygon(100% 0%, 8% 0, 100% 50%, 8% 100%, 100% 100%);
      }
    }
  }
}

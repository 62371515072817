.custom-text-area {
  label.form-label {
    @extend .form-label;
    transition: 0.3s;

    // Pixel perfect to be exactly in the "typeble" area of the input
    top: 10px;
    left: 14px;
  }

  textarea.form-control {
    @extend .form-control;
  }

  p {
    margin-left: 4px;
    transition: 0.3s;
  }

  &[data-active='true'] {
    label.form-label {
      transition: 0.3s;

      // Pixel perfect to place the label above the input
      top: -12px;
      left: 4px;
    }
  }
}

.employee-card {
  scroll-snap-align: center;
  max-width: 500px;
  flex: 1 0 auto;

  img {
    object-fit: contain;
  }

  &--content {
    transition: 0.3s;
    top: 50%;
    background: $lb-employee-card-content-gradient-background;

    &-socials {
      transition: 0.3s;
      top: map-get($spacers, 3);
      right: $lb-employee-card-content-socials-right-position;
    }

    &-info {
      transition: 0.3s;
      left: map-get($spacers, 3);
      bottom: -#{map-get($spacers, 3)};

      .role {
        transition: 0.3s;
        opacity: 0;
      }
    }
  }

  &:hover {
    section.employee-card--content {
      top: 0;

      &-socials {
        transition: 0;
        right: 0;
      }

      &-info {
        bottom: map-get($spacers, 3);

        .role {
          opacity: 1;
        }
      }
    }
  }
}

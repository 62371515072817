.cookie-consent {
  transition: 0.3s;
  z-index: $zindex-cookie-consent;

  &-text {
    transition: 0.3s;
    z-index: $zindex-cookie-consent-text;
  }

  &-image {
    transition: 0.3s;
    width: 150px;
    height: 150px;
    top: -40px;
    left: -40px;
  }

  @include media-breakpoint-up(md) {
    &-text {
      margin-left: 100px;
    }

    &-image {
      width: 200px;
      height: 200px;
      top: -40px;
      left: -100px;
    }
  }
}

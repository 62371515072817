##{$contact} {
  .section-background {
    source,
    img {
      object-fit: cover;
    }
  }

  min-height: $lb-contact-min-height;

  .title-container {
    min-height: $lb-contact-min-height;
  }

  form {
    max-width: 700px;

    textarea {
      min-height: 160px;
    }
  }

  @include media-breakpoint-up(md) {
    form {
      textarea {
        min-height: 320px;
      }
    }
  }
}

.title-container {
  $title-container-clip-path-offset: 1rem;
  transition: $transition-all;

  &--content,
  &--title {
    z-index: $zindex-title-container-title-content;
  }

  &--bg,
  &--title,
  &--content {
    transition: $transition-all;
  }

  &--bg {
    &-start {
      clip-path: polygon(
        0 0,
        calc(100% + $title-container-clip-path-offset) 0%,
        0 calc(100% + $title-container-clip-path-offset)
      );
    }

    &-end {
      clip-path: polygon(100% 0, 100% calc(100% + $title-container-clip-path-offset), 0 0);
    }
  }

  @include media-breakpoint-up(md) {
    &--container {
      // sets a width when not absolute positioned
      width: $lb-title-container-content-container-width-md;
    }

    &--bg {
      &-start {
        clip-path: polygon(0 0, 100% 50%, 0 calc(100% + $title-container-clip-path-offset));
      }

      &-end {
        clip-path: polygon(100% 0, 100% 100%, 0 50%);
      }
    }
  }
}

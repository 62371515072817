.slider-arrow {
  overflow: hidden;
  transition: width 0.3s;
  opacity: 0.25;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &[data-disabled='true'] {
    cursor: auto;
  }

  svg {
    max-height: $lb-team-slider-arrow-height;
  }

  @include media-breakpoint-up(md) {
    width: $lb-team-slider-width-md;
    max-width: $lb-team-slider-max-width-md;
    min-width: $lb-team-slider-min-width-md;

    &[data-disabled='true'] {
      width: $lb-team-slider-width-disabled;
      cursor: auto;
      opacity: 1;
    }

    svg {
      max-height: $lb-team-slider-arrow-height-md;
    }
  }
}

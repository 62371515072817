##{$work-fields} {
  min-height: $lb-work-field-min-height;
  max-height: $lb-work-field-max-height;

  .section-background {
    source,
    img {
      object-fit: cover;
    }
  }

  .title-container {
    min-height: $lb-work-field-min-height;
    max-height: $lb-work-field-max-height;
  }

  @include media-breakpoint-up(md) {
    min-height: $lb-work-field-min-height-md;

    .title-container {
      min-height: $lb-work-field-min-height-md;
    }
  }
}

.custom-checkbox {
  textarea.form-control {
    @extend .form-control;
  }

  .form-check-input {
    @extend .form-check-input;

    $form-check-input-size: 24px;
    height: $form-check-input-size;
    width: $form-check-input-size;
  }

  p {
    margin-left: 4px;
    transition: 0.3s;
  }
}

.custom-btn {
  @extend .btn;

  button {
    svg {
      stroke-width: $lb-button-loading-background-lines-stroke-width;
      stroke-dasharray: $lb-button-loading-background-lines-stroke-array-and-offset;
      stroke-dashoffset: $lb-button-loading-background-lines-stroke-array-and-offset;
      animation: draw-svg 0.5s infinite alternate-reverse;
      transform: scale(1.1);
    }
  }
}

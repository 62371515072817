@keyframes slide-to-down-with-rotate {
  /* Animation to slide down and rotate an element */
  from {
    transform: translateY(-200%) rotate(45deg);
  }

  to {
    transform: translateY(0) rotate(45deg);
  }
}

@keyframes draw-svg {
  /* Animation to draw an SVG path stroke */
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fade-in {
  /* Animation to fade in an element */
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}

##{$about-us} {
  transition: 0.3s;

  .carousel {
    transition: 0.3s;

    .carousel-item {
      transition: $transition-all;
      animation: fade-in 0.3s forwards;
    }
  }

  .section-background {
    transition: 0.3s;

    source,
    img {
      object-fit: cover;
      object-position: 50%;
    }
  }

  .background-lines {
    stroke-width: $lb-about-us-background-lines-width;
    stroke-linecap: round;
    transform: scale(1.1);
    transition: 0.3s;

    path.fill {
      transition: 0.3s;
      opacity: 0;
    }

    &-left {
      left: $lb-about-us-background-lines-x-position;
    }

    &-right {
      right: $lb-about-us-background-lines-x-position;
    }
  }

  section {
    transition: 0.3s;
    max-height: 40vh;
    z-index: $zindex-about-us-text-content;

    .title {
      transition: 0.3s;
      font-size: $lb-about-us-title-font-size;
      text-shadow: $text-shadow;

      // We implement the text align here and below to create an
      // animation when they apply, this was not possible using
      // classNames
      text-align: center;
    }

    section {
      p {
        text-shadow: $text-shadow;
      }

      transition: 0.6s;
      height: 0;
    }
  }

  &.active {
    .section-background {
      filter: brightness(0.5);
    }

    .background-lines {
      path.fill {
        transition: 0.3s;
        opacity: 0.25;
      }
    }

    section {
      transition: 0.3s;

      .title {
        text-align: left;
      }

      section {
        transition: 0.6s;
        height: auto;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .title {
      font-size: $lb-about-us-title-font-size-sm;
    }

    .background-lines {
      &-left {
        left: $lb-about-us-background-lines-x-position-sm;
      }

      &-right {
        right: $lb-about-us-background-lines-x-position-sm;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .background-lines {
      &-left {
        left: $lb-about-us-background-lines-x-position-md;
      }

      &-right {
        right: $lb-about-us-background-lines-x-position-md;
      }
    }
  }

  @media screen and (min-height: 480px) {
    section {
      max-height: 45vh;
    }
  }

  @media screen and (min-height: 860px) {
    section {
      max-height: 60vh;
    }
  }
}
